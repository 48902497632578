html,
body,
#root {
  height: 100%;
  /* enable smooth scrolling on iOS */
  -webkit-overflow-scrolling: touch;
  /* overflow: hidden; */
  /* font-family: 'DM Sans', sans-serif; */
  /* font-size: 14px; */
  /* background-color: #F3F8FB; */
  color: rgba(0, 46, 70, 0.85);
}
